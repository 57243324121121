import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Tab,
  Tabs,
  TabsHeader,
  Typography
} from "@material-tailwind/react";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import driverImage from "../../../../assets/images/driverImg.svg";

const UserDetailsTable = () => {
  const [open, setOpen] = useState(false);
  const [assignRotate, setAssignRotate] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [dialogData, setDialogData] = useState({
    date: "04.06.24",
    driver: "Manish K Singh",
    fleet: "Premium SUV",
    driverId: "LCS001",
  });

  const tabData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Individual Users",
      value: "Individual Users",
    },
    {
      label: "Corporate Users",
      value: "Corporate Users",
    }
  ];

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData(items);
      handleOpen();
    }
  };

   // Updated TABLE_HEAD and TABLE_ROWS based on the screenshot
   const TABLE_HEAD = ["Date", "User Name", "User Id", "Mobile Number", "Email"];

   const TABLE_ROWS = [
     { date: "04.06.24", name: "Manish K Singh", userId: "10000", mobile: "(971) 879 450", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Richa Vyas", userId: "10001", mobile: "(971) 879 453", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Serpo Lama", userId: "10002", mobile: "(971) 879 420", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Mehul", userId: "10003", mobile: "(971) 869 450", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Yukti", userId: "10004", mobile: "(971) 879 250", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Pranit", userId: "10005", mobile: "(971) 879 450", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Manish K Singh", userId: "10006", mobile: "(971) 879 789", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Richa Vyas", userId: "10007", mobile: "(971) 879 320", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Serpo Lama", userId: "10008", mobile: "(971) 879 321", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Mehul", userId: "10009", mobile: "(971) 678 450", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Yukti", userId: "10010", mobile: "(971) 890 450", email: "Dummy1@gmail.com" },
     { date: "04.06.24", name: "Pranit", userId: "10011", mobile: "(971) 456 450", email: "Dummy1@gmail.com" },
   ];

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Driver's Details
        </Typography>
      </div>
      <div className="w-full flex flex-row justify-between items-center">
        <div className="w-[80%] mb-6">
          <Tabs value={activeTab} className="bg-transparent">
            <TabsHeader
              className="bg-transparent"
              indicatorProps={{
                className:
                  "bg-transparent border-b-[2px] rounded-none border-[#FFC978]",
              }}
            >
              {tabData.map(({ label, value }, index) => (
                <Tab
                  key={value}
                  value={value}
                  className={`font-normal ${
                    activeTab === value
                      ? "!text-[#FFC978]"
                      : "!text-primaryLight-300"
                  }`}
                  onClick={() => setActiveTab(value)}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${head === "Email" && "rounded-tr-xl rounded-br-xl"}`}
                  >
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {TABLE_ROWS.map((items, index) => (
                <tr key={index} className="even:bg-primaryLight-800 odd:bg-[#111111]">
                 <td className="p-4 rounded-tl-xl rounded-bl-xl">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    {items.date}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    {items.name}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    {items.userId}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    {items.mobile}
                  </Typography>
                </td>
                <td className="p-4">
                  <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                    {items.email}
                  </Typography>
                </td>
                <td className="p-4 text-center">
                  <Typography
                    className="font-normal font-montserrat text-lg text-textLight-500 cursor-pointer"
                    onClick={() => handleDialog(items)}
                  >
                    View Details
                  </Typography>
                </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dialog Component */}
      <Dialog
  open={open}
  handler={handleOpen}
  size="md"
  className="bg-primaryLight-800 rounded-xl p-4"
>
  <div className="w-full flex flex-row justify-between">
    <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
      Driver Details
    </Typography>
    <Typography
      className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
      onClick={handleOpen}
    >
      x
    </Typography>
  </div>
  <DialogBody>
    <div className="flex flex-row justify-between items-start gap-6">
      {/* Driver Image */}
      <div className="rounded-xl w-[154px] h-[115px]">
        <img
          src={driverImage} // Replace with the driver's image URL
          alt="Driver"
          className="w-full h-full object-cover rounded-md"
        />
      </div>
      {/* Driver Details */}
      <div className="w-full flex flex-row justify-between">
        <div className="text-xs font-bold font-montserrat w-[45%]">
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200">
            Driver Name:{" "}
            <span className="font-normal">{dialogData.name}</span>
          </Typography>
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
            Ride:{" "}
            <span className="font-normal">{dialogData.fleet}</span>
          </Typography>
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
            Mobile Number:{" "}
            <span className="font-normal">9999999999</span>
          </Typography>
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
            Email:{" "}
            <span className="font-normal">driver@gmail.com</span>
          </Typography>
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
            Date of Joining:{" "}
            <span className="font-normal">{dialogData.date}</span>
          </Typography>
          <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
            Total Rides:{" "}
            <span className="font-normal">5</span>
          </Typography>
        </div>
        <div className="border-[1px] border-primaryLight-400 p-4 items-center rounded-lg w-[51%]">
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 w-full">
                  Credit Card Details
                  </Typography>
                  <div className="w-[95%] border-[1px] border-primaryLight-400 my-1"></div>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                  Card No:{" "}
                    <span className="font-normal">1234 1234 1234</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                  Exp Date:<span className="font-normal">27/03/26</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                  CVV:<span className="font-normal">568</span>
                  </Typography>
                </div>
      </div>
    </div>
  </DialogBody>
  <DialogFooter className="w-full flex justify-end gap-6">
    <Button
      variant="text"
      color="red"
      size="lg"
      onClick={handleOpen}
      className="border border-red-500 text-red-500 font-normal px-4 py-2 rounded-md normal-case"
    >
      Delete Profile
    </Button>
  </DialogFooter>
</Dialog>

    </div>
  );
};

export default UserDetailsTable;
