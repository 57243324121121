import React from "react";
import { Button, Typography } from "@material-tailwind/react";
import { Profile2User, ShoppingBag } from "iconsax-react";

const UserRidesScheduled = ({ items }) => {
  return (
    <div className="flex flex-row w-full py-8 gap-6">
      <div className="w-1/3">
        <img src={items.carImg} alt="car" className="w-full h-full" />
      </div>
      <div className="w-2/3 flex flex-col justify-between">
        <div className="flex w-full flex-row">
          <Typography className="text-left w-full text-xl font-semibold font-playfair text-primaryLight-200">
            {items.carType}
          </Typography>
          <Typography className="text-right w-full text-xl font-semibold font-playfair text-primaryLight-200">
            ${items.fare}
          </Typography>
        </div>
        <div className="flex w-full flex-row">
          <div className="flex w-full flex-col gap-2">
            <div className="flex flex-row gap-2 mt-2">
              <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-800 p-2 bg-[#d1cbc7]">
                <Profile2User size="16" className="mr-2" /> {items.seats}
              </Typography>
              <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-50 p-2 bg-[#d1cbc7] bg-opacity-30">
                <ShoppingBag size="16" className="mr-2" /> {items.luggage}
              </Typography>
            </div>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.wifi}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.compli}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              <span className="pl-[3px] font-normal">{items.name}</span>
            </Typography>
          </div>
          <div className="flex flex-row gap-2 w-full justify-end items-end">
            <Button
              variant="filled"
              className="bg-[#FFC978] !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]">
              Modify
            </Button>
            <Button
              variant="outlined"
              color="red"
              size="md"
              className="normal-case w-[120px] rounded-[4px]">
              Cancel Ride
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRidesScheduled;
