import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { useNavigate } from "react-router";
import { Calendar2 } from "iconsax-react";
import { useRequestRideMutation } from "../../../../redux/api/userApi";
import BG from "../../../../assets/images/bg.png";
import heroCar from "../../../../assets/images/hero-car.png";
const Banner = () => {
  const token = localStorage.getItem("token");
  const [requestRide] = useRequestRideMutation();
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedRide, setSelectedRide] = useState("sedan"); // Default value
  const [pickupAddress, setPickupAddress] = useState(""); // State for pickup address
  const [dropoffAddress, setDropoffAddress] = useState(""); // State for dropoff address
  const [pickupDate, setPickupDate] = useState({
    date: "2024-09-30T19:31:39.280Z", // Initial ISO string date
    time: {
      hour: 9,
      minute: 50,
      period: "PM",
    },
  }); // State for pickup date
  const [pickupSuggestions, setPickupSuggestions] = useState([]); // State for pickup suggestions
  const [dropoffSuggestions, setDropoffSuggestions] = useState([]); // State for dropoff suggestions
  const [isRideRequired, setIsRideRequired] = useState(false);
  const rideSelectRef = useRef(null);

  const navigate = useNavigate();
  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN; // Replace with your Mapbox token

  // Fetch suggestions for pickup address
  const fetchPickupSuggestions = async (query) => {
    if (!query) return;
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&limit=5`
      );
      setPickupSuggestions(response.data.features);
    } catch (error) {
      console.error("Error fetching pickup suggestions:", error);
    }
  };

  // Fetch suggestions for dropoff address
  const fetchDropoffSuggestions = async (query) => {
    if (!query) return;
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&limit=5`
      );
      setDropoffSuggestions(response.data.features);
    } catch (error) {
      console.error("Error fetching dropoff suggestions:", error);
    }
  };

  const fetchCoordinates = async (address) => {
    const mapboxToken = process.env.REACT_APP_MAPBOX_TOKEN; // Replace with your Mapbox token
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        address
      )}.json?access_token=${mapboxToken}`
    );

    if (!response.ok) {
      throw new Error("Error fetching coordinates");
    }

    const data = await response.json();
    // Return the first result's coordinates
    return data.features.length > 0
      ? data.features[0].geometry.coordinates
      : null;
  };

  // Example menu items for ride types
  const rideTypes = [
    { label: "Airport NY", value: "airportNYC" },
    { label: "Airport Outside NY", value: "airportONYC" },
    { label: "Point to Point", value: "pointToPoint" },
    { label: "Hourly", value: "hourly" },
    { label: "Groups And Events", value: "groupsAndEvents" },
    { label: "Private", value: "private" },
    { label: "City Tour", value: "cityTour" },
  ];

  const handleDateChange = (e) => {
    const selectedDateTime = new Date(e.target.value); // Convert the input to a Date object
    const formattedDate = selectedDateTime.toISOString(); // Get ISO string

    const hour = selectedDateTime.getHours(); // Get hour (0-23)
    const minute = selectedDateTime.getMinutes(); // Get minute (0-59)
    const period = hour >= 12 ? "PM" : "AM"; // Determine AM/PM

    // Update the state with the desired structure
    setPickupDate({
      date: formattedDate,
      time: {
        hour: hour % 12 || 12, // Convert to 12-hour format
        minute: minute < 10 ? `0${minute}` : minute, // Add leading zero if needed
        period: period,
      },
    });
    localStorage.setItem("hour", hour);
    localStorage.setItem("minute", minute);
    localStorage.setItem("period", period);
    localStorage.setItem("date", formattedDate);
  };

  useEffect(() => {
    const persistedPickup = localStorage.getItem("pickupAddress");
    const persistedDropoff = localStorage.getItem("dropoffAddress");
    const persistedDate = localStorage.getItem("date");
    const persistedRideType = localStorage.getItem("selectedRide");

    if (persistedPickup) setDropoffAddress(persistedPickup);
    if (persistedDropoff) setDropoffAddress(persistedDropoff);
    if (persistedDate) setPickupDate(persistedDate);
    if (persistedRideType) setSelectedRide(persistedRideType);
  }, []);

  const handleClick = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/login");
    }
    if (!selectedRide) {
      setIsRideRequired(true); // Set required validation state to true
      rideSelectRef.current.focus(); // Focus on the dropdown for better UX
      return; // Exit the function if no ride type is selected
    }

    try {
      const pickupCoords = await fetchCoordinates(pickupAddress);
      const dropoffCoords = await fetchCoordinates(dropoffAddress);
      const data = {
        pickup: {
          address: pickupAddress,
          lon: pickupCoords ? pickupCoords[0] : null,
          lat: pickupCoords ? pickupCoords[1] : null,
        },
        dropof: {
          address: dropoffAddress,
          lon: dropoffCoords ? dropoffCoords[0] : null,
          lat: dropoffCoords ? dropoffCoords[1] : null,
        },
        fromDate: pickupDate.date,
        time: pickupDate.time,
        rideType: rideTypes.find((ride) => ride.value === selectedRide)?.value,
      };
      console.log("Data", data);
      const res = await requestRide({ data, token });
      if (res.data.success) {
        console.log(res.data.requestId);

        localStorage.removeItem("pickupAddress");
        localStorage.removeItem("dropoffAddress");
        localStorage.removeItem("date");
        localStorage.removeItem("selectedRide");
        navigate("/ride-booking", { state: { requestId: res.data.requestId } });
      }
    } catch (error) {
      console.error("Error fetching coordinates:", error);
    }
  };

  return (
    <div className="min-h-screen h-full">
      <div
        className="flex flex-col justify-evenly h-full min-h-screen bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-6"
        style={{
          backgroundImage: `url(${BG})`,
          backgroundRepeat: "no-repeat",
        }}>
        {/* Top Section */}
        <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between w-full h-full">
          <div className="w-full md:w-2/3">
            <Typography className="text-left text-3xl font-semibold font-playfair text-primaryLight-50">
              Ride in{" "}
              <span className="font-bold text-5xl text-[#ffc978]">Style,</span>
            </Typography>
            <Typography className="text-left text-3xl font-semibold font-playfair text-primaryLight-50">
              Arrive in{" "}
              <span className="font-bold text-5xl text-[#ffc978]">
                Comfort.
              </span>
            </Typography>
          </div>
          <div className="max-w-[350px] w-full">
            <Typography className="text-left max-w-[312px] text-xs font-normal mb-4 font-montserrat text-primaryLight-50">
              Escape the ordinary and step into a world of unparalleled
              chauffeured experiences. We're here to whisk you away in comfort
              and style, ensuring your journey is as unforgettable as your
              destination.
            </Typography>
            <a href="#openfleet">
              <Button
                variant="filled"
                className="bg-[#FFC978] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]">
                Open Fleet
              </Button>
            </a>
          </div>
        </div>

        {/* Car Image */}
        <div className="flex items-center justify-center w-full h-full">
          <img src={heroCar} alt="carImg" />
        </div>

        {/* Input Section */}
        <form onSubmit={handleClick}>
          <div className="w-full flex justify-center">
            <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row items-center gap-4 lg:gap-0 justify-between w-full h-full border-[1px] border-[#d1cbc7] bg-[#ffffff]/10 rounded-xl p-5">
              {/* Pickup Address */}
              <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7] relative">
                <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                  Pick Up Address
                </Typography>
                <input
                  type="text"
                  placeholder="Pickup Address"
                  value={pickupAddress}
                  onChange={(e) => {
                    setPickupAddress(e.target.value);
                    fetchPickupSuggestions(e.target.value); // Fetch suggestions
                    localStorage.setItem("pickupAddress", e.target.value);
                  }}
                  required
                  className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"></input>
                {/* Suggestions Dropdown */}
                {pickupSuggestions.length > 0 && (
                  <ul className="absolute z-50 mt-20 w-full bg-[#4A2C2A] text-white rounded-lg shadow-lg max-h-40 overflow-y-auto">
                    {pickupSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        onClick={() => {
                          setPickupAddress(suggestion.place_name); // Set selected address
                          setPickupSuggestions([]); // Clear suggestions
                          localStorage.setItem(
                            "pickupAddress",
                            suggestion.place_name
                          );
                        }}
                        className="cursor-pointer hover:bg-[#6F4F4F] p-2">
                        {suggestion.place_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Drop Off Address */}
              <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7] relative">
                <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                  Drop Off Address
                </Typography>
                <input
                  type="text"
                  placeholder="Drop Off Address"
                  value={dropoffAddress}
                  required
                  onChange={(e) => {
                    setDropoffAddress(e.target.value);
                    fetchDropoffSuggestions(e.target.value); // Fetch suggestions
                    localStorage.setItem("dropoffAddress", e.target.value);
                  }}
                  className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"
                />
                {/* Suggestions Dropdown */}
                {dropoffSuggestions.length > 0 && (
                  <ul className="absolute z-50 mt-20 w-full bg-[#4A2C2A] text-white rounded-lg shadow-lg max-h-40 overflow-y-auto">
                    {dropoffSuggestions.map((suggestion) => (
                      <li
                        key={suggestion.id}
                        onClick={() => {
                          setDropoffAddress(suggestion.place_name); // Set selected address
                          setDropoffSuggestions([]); // Clear suggestions
                          localStorage.setItem(
                            "dropoffAddress",
                            suggestion.place_name
                          );
                        }}
                        className="cursor-pointer hover:bg-[#6F4F4F] p-2">
                        {suggestion.place_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Pickup Date */}
              <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7]">
                <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                  Pick Up Date
                </Typography>
                <input
                  type="datetime-local"
                  onChange={handleDateChange} // Use the new change handler
                  required
                  className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"
                />
              </div>

              {/* Ride Type */}
              <div className="w-full flex items-center gap-2 flex-col border-[#d1cbc7]">
                <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                  Type of Ride
                </Typography>
                <Menu open={openMenu} handler={setOpenMenu} ref={rideSelectRef}>
                  <MenuHandler>
                    <Typography className="w-full lg:w-fit text-left text-sm font-normal font-montserrat text-primaryLight-50 cursor-pointer flex items-center gap-3">
                      {rideTypes.find((type) => type.value === selectedRide)
                        ?.label || "Select Ride"}
                      <ChevronDownIcon
                        strokeWidth={2.5}
                        className={`h-3.5 w-3.5 transition-transform ${
                          openMenu ? "rotate-180" : ""
                        }`}
                      />
                    </Typography>
                  </MenuHandler>
                  <MenuList className="w-[200px] max-w-xs rounded-lg bg-brown-500 shadow-lg">
                    {rideTypes.map(({ label, value }) => (
                      <MenuItem
                        key={value}
                        onClick={() => {
                          setSelectedRide(value); // Update selected ride value
                          localStorage.setItem("selectedRide", value);
                          setOpenMenu(false); // Close menu on selection
                          setIsRideRequired(false); // Reset required state on selection
                        }}
                        className="text-sm text-primaryLight-50 hover:bg-gray-100">
                        {label}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </div>

              {isRideRequired && (
                <Typography className="text-red-500 text-sm mt-2">
                  Please select a ride type.
                </Typography>
              )}

              {/* Reserve Button */}
              <div className="w-full lg:max-w-[138px] lg:max-w-full flex items-left flex-col">
                <Button
                  variant="filled"
                  className="bg-[#FFC978] flex flex-col gap-1 items-center text-md font-montserrat text-black h-[70px] hover:bg-[#ffcc33] shadow-none normal-case rounded-[8px]"
                  type="submit">
                  <Calendar2 color="#40342C" />
                  Reserve now
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Banner;
