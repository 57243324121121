import { Button, Input, Typography } from "@material-tailwind/react";
import { Call, Profile2User, ShoppingBag, Sms } from "iconsax-react";
import React from "react";
import {
  useGetRequestQuery,
  useMyProfileQuery,
} from "../../../../redux/api/userApi";
import { usePaymentIntentMutation } from "../../../../redux/api/paymentApi";

const PersonalDetails = ({ payload, handleNext, data, setClient_Secret }) => {
  const token = localStorage.getItem("token");
  const { data: requestData } = useGetRequestQuery({ payload, token });
  const { data: userData } = useMyProfileQuery(token);

  const [paymentIntent] = usePaymentIntentMutation();

  const time = requestData?.request?.time;
  const timeObject = `${time?.hour} : ${time?.minute} ${time?.period}`;

  const rideTypesMap = {
    airportNYC: "Airport NY",
    airportONYC: "Airport ONY",
    pointToPoint: "Point to Point",
    hourly: "Hourly",
    groupsAndEvents: "Groups and Events",
    private: "Private",
    cityTour: "City Tour",
  };

  const displayRideType =
    rideTypesMap[requestData?.request?.rideType] ||
    requestData?.request?.rideType;

  const onSubmit = async () => {
    const amount = data?.fare;
    console.log("Amount", amount);
    
    try {
      const res = await paymentIntent({ body: { amount }, token });
      console.log(res);

      if (res.data.success) {
        console.log(res.data?.clientSecret);
        setClient_Secret(res?.data?.clientSecret);
        handleNext();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full h-full gap-8 flex lg:flex-row flex-col justify-start">
      <div className="lg:w-2/5 w-full">
        <div className="mb-4">
          <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center py-2 px-4 gap-4 mb-6">
            <div className="border-r-[2px] border-[#6b564a] pr-2">
              <Profile2User className="text-[#6b564a]" />
            </div>
            <Input
              label="Passenger Name"
              // value=""
              // onChange={handleTypeOfRidesChange}
              className="border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent text-[#6b564a] peer w-full"
              // labelProps={{
              //   className:
              //     "peer-focus:text-[#302721] !text-[#6b564a] peer-focus:border-transparent",
              // }}
              labelProps={{
                className: "hidden",
              }}
              value={userData?.userDetails?.name}
            />
          </div>
          <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center py-2 px-4 gap-4 mb-6">
            <div className="border-r-[2px] border-[#6b564a] pr-2">
              <Sms className="text-[#6b564a]" />
            </div>
            <Input
              label="Email Id"
              // value=""
              // onChange={handleTypeOfRidesChange}
              className="border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent text-[#6b564a] peer w-full"
              // labelProps={{
              //   className:
              //     "peer-focus:text-[#302721] !text-[#6b564a] peer-focus:border-transparent",
              // }}
              labelProps={{
                className: "hidden",
              }}
              value={userData?.userDetails?.email}
            />
          </div>
          <div className="bg-[#302721] rounded-lg flex flex-row justify-center items-center py-2 px-4 gap-4 mb-6">
            <div className="border-r-[2px] border-[#6b564a] pr-2">
              <Call className="text-[#6b564a]" />
            </div>
            <Input
              label="Mobile Number"
              // value=""
              // onChange={handleTypeOfRidesChange}
              className="border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent text-[#6b564a] peer w-full"
              // labelProps={{
              //   className:
              //     "peer-focus:text-[#302721] !text-[#6b564a] peer-focus:border-transparent",
              // }}
              labelProps={{
                className: "hidden",
              }}
              value={
                userData?.userDetails?.phone ||
                "Update your phone on user profile"
              }
            />
          </div>

          {/* New "Message for Driver" Input */}
          <div className="bg-[#302721] rounded-lg flex flex-col justify-center py-2 px-4 gap-4 mb-6">
            <label className="text-[#6b564a]">Message for Driver</label>
            <textarea
              rows="3"
              placeholder="Enter a message for the driver"
              className="resize-none border-none focus:ring-0 focus:border-none outline-none pl-2 bg-transparent text-[#6b564a] w-full"
            />
          </div>

          {/* Continue Button */}
          <Button
            className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition"
            onClick={() => {
              // handleNext();
              onSubmit();
            }}>
            Continue
          </Button>
        </div>
      </div>
      <div className="lg:w-3/5 w-full p-4 flex flex-row gap-4 justify-between border-primaryLight-300 rounded-[20px] border-[1px] ">
        <div className="flex flex-col items-center h-full justify-between w-[280px]">
          <Typography className="text-left w-[90%] flex flex-row items-center gap-4 text-2xl mb-6 font-bold font-montserrat text-primaryLight-200">
            Summary
          </Typography>
          <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] mb-4"></div>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
            <span className="pl-[3px] font-medium">Pickup</span>
          </Typography>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
            <span className="pl-[3px] font-medium">
              {requestData?.request?.pickup?.address}
            </span>
          </Typography>
          <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
            <span className="pl-[3px] font-medium">Drop Off</span>
          </Typography>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
            <span className="pl-[3px] font-medium">
              {requestData?.request?.dropof?.address}
            </span>
          </Typography>
          <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
            <span className="pl-[3px] font-medium">Pickup Date & Time</span>
          </Typography>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
            <span className="pl-[3px] font-medium">
              {requestData?.request?.fromDate?.split("T")[0]} & {timeObject}
            </span>
          </Typography>
          <div className="border-b-[4px] border-dotted border-primaryLight-300 flex w-[90%] my-4"></div>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50 text-opacity-50">
            <span className="pl-[3px] font-medium">Type of ride</span>
          </Typography>
          <Typography className="text-left w-full text-sm font-semibold font-montserrat text-primaryLight-50">
            <span className="pl-[3px] font-medium">{displayRideType}</span>
          </Typography>
        </div>
        <div className="flex flex-col w-full gap-6">
          <div className="w-full border-[2px] border-[#B59278] rounded-[24px]">
            <img
              src={data?.carImg}
              alt="car"
              className="w-full h-[250px] object-contain"
            />
          </div>
          <div className="w-full flex flex-col justify-between">
            <div className="flex w-full flex-row mb-2">
              <Typography className="text-left w-full text-xl font-semibold font-playfair text-primaryLight-200">
                {data?.name}
              </Typography>
              <Typography className="text-right w-full text-xl font-semibold font-playfair text-primaryLight-200">
                ${data?.fare}
              </Typography>
            </div>
            <div className="flex w-full flex-row">
              <div className="flex w-full flex-col gap-2">
                <div className="flex flex-row gap-2">
                  <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-800 p-2 bg-[#d1cbc7]">
                    <Profile2User size="16" className="mr-2" /> {data?.seats}
                  </Typography>
                  <Typography className="text-center w-full max-w-[50px] rounded-md text-sm flex flex-row items-center justify-center font-semibold font-montserrat text-primaryLight-50 p-2 bg-[#d1cbc7] bg-opacity-30">
                    <ShoppingBag size="16" className="mr-2" /> {data?.lugage}
                  </Typography>
                </div>
                <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
                  <span className="pl-[3px] font-normal">
                    Free Wifi Onboard
                  </span>
                </Typography>
                <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
                  <span className="pl-[3px] font-normal">
                    Complimentary Beverages
                  </span>
                </Typography>
                <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
                  <span className="pl-[3px] font-normal">{data?.type}</span>
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
