import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

const BlogCard2 = ({ item, setReadBlog }) => {
  return (
    <Card className="w-full h-[151px] flex flex-row rounded-2xl overflow-hidden shadow-lg cursor-pointer" onClick={()=>setReadBlog(true)}>
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="m-0 rounded-none w-[35%]"
      >
        <img
          src={item?.blogImg}
          alt="blog cover"
          className="object-cover w-full h-full"
        />
      </CardHeader>
      <CardBody className="p-2 w-[65%]">
        <Typography className="font-playfair text-2xl font-bold mb-4 text-[#302721]">
          {item?.blogTitle}
        </Typography>
        <Typography className="font-montserrat text-xs font-medium text-[#6B564A] text-opacity-60 mb-1">
          Published By {item?.Author}, {item?.date}
        </Typography>
        <Typography className="font-montserrat text-sm text-[#6B564A] text-opacity-60'">
          {item?.description.length > 60
            ? item.description.substring(0, 50) + "... Read more."
            : item.description}
        </Typography>
      </CardBody>
    </Card>
  );
};

export default BlogCard2;