import {
  Button,
  Typography
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "../../../../assets/images/blogbanner.png";
import BlogCard1 from "../../../../common/components/BlogCard1";
import BlogCard2 from "../../../../common/components/BlogCard1/BlogCard2";
import ReadBlog from "./ReadBlog";
import "./style.css";

const Blogs = () => {
  // const [swiperRef, setSwiperRef] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [readBlog, setReadBlog] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(3); // Default is 3 for larger screens

  const blogData = [
    {
      blogImg:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
      blogTitle: "Top 5 Day Trips From New York City for Labor Day",
      date: "Pranit Sharma 18.08.24",
      description:
        "Labor Day is just around the corner, which means a well-deserved holiday! And what better way to spend it than by escaping the hustle and bustle of NYC? Whether you are carving for a beachside retreat, a scenic countryside adventure, or a cultural city experience, there are plenty of spots just a short drive away from NYC. But why drive yourself and deal with traffic or parking? Let Lryde Chauffeur Services (LCS) handle the driving while you relax in comfort and style. Our professional chauffeurs ensure that your getaway is smooth, allowing you to make the most of your day trip.",
    },
    {
      blogImg:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
      blogTitle: "Why USA is Rejecting Tesla!",
      Author: "David Pinto",
      date: "20.03.24",
      description:
        "The prices of luxury cars have skyrocketed over the past couple of years...",
    },
    {
      blogImg:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
      blogTitle: "The Last V8 Aspirated Mustang",
      Author: "David Pinto",
      date: "20.03.24",
      description:
        "The prices of luxury cars have skyrocketed over the past...",
    },
    {
      blogImg:
        "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
      blogTitle: "Which are the most VFM Luxury Cars in today's market?",
      Author: "David Pinto",
      date: "20.03.24",
      description:
        "The prices of luxury cars have skyrocketed over the past couple of years...",
    },
  ];

  // Set the number of slides based on the window width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setSlidesPerView(1); // 1 slide for screens smaller than 'lg'
      } else {
        setSlidesPerView(3); // 3 slides for larger screens
      }
    };

    handleResize(); // Set the initial value
    window.addEventListener("resize", handleResize);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="min-h-screen lg:mx-16 md:mx-12 mx-4 flex flex-col items-center">
      {/* Banner Section */}
      <div
        className="flex flex-col items-center justify-end h-[193px] rounded-3xl w-full bg-cover p-4 mb-14"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: "no-repeat",
        }}>
        <Typography className="text-5xl font-[800] font-playfair text-primaryLight-50 shadow-xl mb-2">
          Blog
        </Typography>
        <Typography className="text-base font-medium font-montserrat text-primaryLight-50 shadow-xl">
          Read our latest posts to stay up to date with new trends
        </Typography>
      </div>
      {readBlog ? (
        <ReadBlog setReadBlog={setReadBlog} />
      ) : (
        <div className="flex flex-col w-full mb-14">
          <Typography className="text-3xl font-[800] font-playfair text-white shadow-xl mb-6">
            Freshly Published
          </Typography>
          <div className="w-full flex justify-between">
            <div className="hidden lg:flex w-[42%]">
              <BlogCard1 item={blogData[0]} setReadBlog={setReadBlog} />
            </div>
            <div className="lg:w-[56%] w-full flex flex-col gap-6 justify-between">
              <div className="lg:hidden flex">
                <BlogCard2 item={blogData[0]} setReadBlog={setReadBlog} />
              </div>
              <BlogCard2 item={blogData[1]} setReadBlog={setReadBlog} />
              <BlogCard2 item={blogData[2]} setReadBlog={setReadBlog} />
              <BlogCard2 item={blogData[3]} setReadBlog={setReadBlog} />
            </div>
          </div>
        </div>
      )}

      <div className="w-full flex flex-col justify-center gap-6 bg-[#37302e] items-center h-full lg:px-14 md:px-8 px-6 pb-14 pt-6 mb-8 rounded-xl">
        <div className="w-full h-full items-center gap-6 flex flex-col">
          {readBlog ? (
            <Typography className="text-center text-5xl items-center font-bold font-playfair text-primaryLight-50">
              Recommended Blogs
            </Typography>
          ) : (
            <Typography className="text-center text-5xl items-center font-bold font-playfair text-primaryLight-50">
              All Blogs
            </Typography>
          )}
          <Typography className="text-center text-sm font-montserrat max-w-[440px] text-primaryLight-50">
            Read our new blogs every friday
          </Typography>
        </div>
        <div className="flex flex-col w-full h-full items-start justify-between">
          <div className="w-full gap-4">
            <Swiper
              slidesPerView={slidesPerView}
              slidesPerGroup={1}
              spaceBetween={30}
              pagination={{
                el: ".swiper-custom-pagination",
              }}
              navigation={{
                prevEl: ".custom-prev",
                nextEl: ".custom-next",
              }}
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay]}
              className="mySwiper">
              {blogData.map((slide, index) => (
                <SwiperSlide key={index} className="relative group">
                  <div className="w-full h-full flex justify-center items-center">
                    <div className="flex items-end justify-center w-full h-full">
                      <div className="w-full max-w-[95vw] md:max-w-[450px] overflow-hidden rounded-2xl shadow-lg hover:shadow-2xl transition-shadow duration-300">
                        <BlogCard1 item={slide} setReadBlog={setReadBlog} />
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-custom-pagination" />
            <div className="w-full flex justify-center items-center">
              <Button
                variant="filled"
                className="bg-[#FFC978] !w-[124px] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px] mt-10">
                Read More
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
