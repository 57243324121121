import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { Calendar2 } from "iconsax-react";
import { useState } from "react";

const Hero = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedRide, setSelectedRide] = useState("sedan"); // Default value
  const [pickupAddress, setPickupAddress] = useState(""); // State for pickup address
  const [dropoffAddress, setDropoffAddress] = useState(""); // State for dropoff address
  const [pickupDate, setPickupDate] = useState("12:00, 21 / 05 / 2024"); // State for pickup date

  // Example menu items
  const rideTypes = [
    { label: "Sedan", value: "sedan" },
    { label: "SUV", value: "suv" },
    { label: "First Class", value: "first_class" },
  ];

  return (
    <div className="min-h-screen h-full">
      <div
        className="flex flex-col justify-evenly h-full min-h-screen bg-cover rounded-[20px] lg:mx-16 md:mx-8 mx-4 lg:p-8 p-6"
        style={{
          backgroundImage: `url("https://i.imgur.com/QnkNISX.png")`,
          backgroundRepeat: "no-repeat",
        }}>
        <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between w-full h-full">
          <div className="w-full md:w-2/3">
            <Typography className="text-left text-3xl font-semibold font-playfair text-primaryLight-50">
              Ride in{" "}
              <span className="font-bold text-5xl text-[#ffc978]">Style,</span>
            </Typography>
            <Typography className="text-left text-3xl font-semibold font-playfair text-primaryLight-50">
              Arrive in{" "}
              <span className="font-bold text-5xl text-[#ffc978]">
                Comfort.
              </span>
            </Typography>
          </div>
          <div className="max-w-[350px] w-full">
            <Typography className="text-left max-w-[312px] text-xs font-normal mb-4 font-montserrat text-primaryLight-50">
              Escape the ordinary and step into a world of unparalleled
              chauffeured experiences. We&apos;re here to whisk you away in
              comfort and style, ensuring your journey is as unforgettable as
              your destination.
            </Typography>
            <a href="#openfleet">
              <Button
                variant="filled"
                className="bg-[#FFC978] text-black hover:bg-[#ffcc33] shadow-none normal-case rounded-[4px]">
                Open Fleet
              </Button>
            </a>
          </div>
        </div>
        <div className="flex items-center justify-center w-full h-full">
          <img src="https://i.imgur.com/UgmmBIv.png" alt="carImg" />
        </div>
        <div className="w-full flex justify-center">
          <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row items-center gap-4 lg:gap-0 justify-between w-full h-full border-[1px] border-[#d1cbc7] bg-[#ffffff]/10 rounded-xl p-5">
            <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7]">
              <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                Pick Up Address
              </Typography>
              <input
                type="text"
                placeholder="Pickup Address"
                value={pickupAddress}
                onChange={(e) => setPickupAddress(e.target.value)}
                className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"
              />
            </div>
            <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7]">
              <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                Drop Off Address
              </Typography>
              <input
                type="text"
                placeholder="Drop Off Address"
                value={dropoffAddress}
                onChange={(e) => setDropoffAddress(e.target.value)}
                className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"
              />
            </div>
            <div className="w-full flex items-center gap-2 flex-col border-0 lg:border-r-[1px] lg:border-[#d1cbc7]">
              <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                Pick Up Date
              </Typography>
              <input
                type="datetime-local"
                value={pickupDate}
                onChange={(e) => setPickupDate(e.target.value)}
                className="w-full lg:w-fit text-center text-sm font-normal font-montserrat text-primaryLight-50 bg-transparent border-none focus:outline-none cursor-text"
              />
            </div>
            <div className="w-full flex items-center gap-2 flex-col border-[#d1cbc7]">
              <Typography className="w-full lg:w-fit text-left text-base font-semibold font-montserrat text-primaryLight-50">
                Type of Ride
              </Typography>
              <Menu open={openMenu} handler={setOpenMenu}>
                <MenuHandler>
                  <Typography className="w-full lg:w-fit text-left text-sm font-normal font-montserrat text-primaryLight-50 cursor-pointer flex items-center gap-3">
                    {rideTypes.find((type) => type.value === selectedRide)
                      ?.label || "Select Ride"}
                    <ChevronDownIcon
                      strokeWidth={2.5}
                      className={`h-3.5 w-3.5 transition-transform ${
                        openMenu ? "rotate-180" : ""
                      }`}
                    />
                  </Typography>
                </MenuHandler>
                <MenuList className="w-[200px] max-w-xs rounded-lg bg-brown-500 shadow-lg">
                  {rideTypes.map(({ label, value }) => (
                    <MenuItem
                      key={value}
                      onClick={() => {
                        setSelectedRide(value); // Update selected ride value
                        setOpenMenu(false); // Close menu on selection
                      }}
                      className="text-sm text-primaryLight-50 hover:bg-gray-100">
                      {label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </div>
            <div className="w-full lg:max-w-[138px] lg:max-w-full flex items-left flex-col">
              <Button
                variant="filled"
                className="bg-[#FFC978] flex flex-col gap-1 items-center text-md font-montserrat text-black h-[70px] hover:bg-[#ffcc33] shadow-none normal-case rounded-[8px]">
                <Calendar2 color="#40342C" />
                Reserve now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
