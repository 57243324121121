import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const urls = [
  "https://lcs-reboot-go97.onrender.com/api/admin",
  "http://localhost:6969/api/admin",
];
export const adminAPI = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl: urls[0],
  }),
  endpoints: (builder) => ({
    scheduledRides: builder.query({
      query: (token) => ({
        url: "/scheduled-rides",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    pendingRequests: builder.query({
      query: ({ page, token }) => ({
        url: `/pending-request?page=${page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    assignDriver: builder.mutation({
      query: ({ token, params }) => ({
        url: "/assign-driver",
        method: "POST",
        body: params,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    drivers: builder.query({
      query: (token) => ({
        url: "/drivers",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    // driver details page
    getAllDrivers: builder.query({
      query: ({ token, page }) => ({
        url: `/get-drivers?page=${page}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
    addDriver: builder.mutation({
      query: ({ body, token }) => ({
        url: "/add-driver",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),
  }),
});

export const {
  useScheduledRidesQuery,
  usePendingRequestsQuery,
  useAssignDriverMutation,
  useCancelRequestMutation,
  useDriversQuery,
  useSearchDriverQuery,
  useGetAllDriversQuery,
  useAddDriverMutation,
} = adminAPI;
