import {
  Dialog,
  DialogBody,
  Tab,
  Tabs,
  TabsHeader,
  Typography
} from "@material-tailwind/react";
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import carLocationImg from "../../../../assets/images/carLocationImg.svg";

const RIdeDetailsTable = () => {
  const [open, setOpen] = useState(false);
  const [assignRotate, setAssignRotate] = useState(false);
  const [activeTab, setActiveTab] = useState("All");
  const [dialogData, setDialogData] = useState({
    date: "04.06.24",
    driver: "Manish K Singh",
    ride: "Premium SUV",
    amount: "$700",
    status: "Completed",
  });

  const tabData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Scheduled",
      value: "Scheduled",
    },
    {
      label: "Cancelled",
      value: "Cancelled",
    },
    {
      label: "Request Pending",
      value: "Request Pending",
    },
  ];

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData(items);
      handleOpen();
    }
  };

  // Updated TABLE_HEAD and TABLE_ROWS based on screenshot
  const TABLE_HEAD = ["Date", "Driver Assigned", "Ride", "Amount", "Status"];

  const TABLE_ROWS = [
    {
      date: "04.06.24",
      driver: "Manish K Singh",
      ride: "Premium SUV",
      amount: "$700",
      status: "Completed",
    },
    {
      date: "04.06.24",
      driver: "Richa Vyas",
      ride: "Premium SUV",
      amount: "$750",
      status: "Cancelled",
    },
    {
      date: "04.06.24",
      driver: "Serpo Lama",
      ride: "Premium Sedan",
      amount: "$1500",
      status: "Cancelled",
    },
    {
      date: "04.06.24",
      driver: "Mehul",
      ride: "First Class",
      amount: "$800",
      status: "Completed",
    },
    {
      date: "04.06.24",
      driver: "Yukti",
      ride: "Premium Sedan",
      amount: "$950",
      status: "Completed",
    },
    {
      date: "04.06.24",
      driver: "Pranit",
      ride: "First Class",
      amount: "$800",
      status: "Completed",
    },
  ];

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Ride Details
        </Typography>
      </div>
      <div className="w-[83%] mb-6">
        <Tabs value={activeTab} className="bg-transparent">
          <TabsHeader
            className="bg-transparent"
            indicatorProps={{
              className:
                "bg-transparent border-b-[2px] rounded-none border-[#FFC978]",
            }}
          >
            {tabData.map(({ label, value }, index) => (
              <Tab
                key={value}
                value={value}
                className={`font-normal ${
                  activeTab === value
                    ? "!text-[#FFC978]"
                    : "!text-primaryLight-300"
                }`}
                onClick={() => setActiveTab(value)}
              >
                {label}
              </Tab>
            ))}
          </TabsHeader>
        </Tabs>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${head === "Status" && "rounded-tr-xl rounded-br-xl"}`}
                  >
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {TABLE_ROWS.map((items, index) => (
                <tr
                  key={index}
                  className="even:bg-primaryLight-800 odd:bg-[#111111] overflow-y-auto"
                >
                  <td className="p-4 rounded-tl-xl rounded-bl-xl">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.date}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.driver}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.ride}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.amount}
                    </Typography>
                  </td>
                  <td
                    className={`p-4 ${
                      items.status === "Cancelled"
                        ? "text-red-500"
                        : "text-primaryLight-50"
                    } rounded-tr-xl rounded-br-xl`}
                  >
                    <Typography className="font-normal font-montserrat text-lg">
                      {items.status}
                    </Typography>
                  </td>
                  <td className="p-4 bg-[#111111] text-center">
                    <Typography
                      className="font-normal font-montserrat text-lg text-textLight-500 cursor-pointer"
                      onClick={() => handleDialog(items)}
                    >
                      View Details
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dialog Component */}
      <Dialog
        open={open}
        handler={handleOpen}
        size="lg"
        className="bg-primaryLight-800 rounded-xl p-4"
      >
        <div className="w-full flex flex-row justify-between">
          <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
            Ride Details
          </Typography>
          <Typography
            className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
            onClick={handleOpen}
          >
            x
          </Typography>
        </div>
        <DialogBody>
          <div className="flex flex-row justify-between items-start gap-6 h-full">
            {/* Map / Image */}
            <div className="rounded-xl w-[46%] h-full">
              <img src={carLocationImg} alt="Map" className="w-full h-full" />
            </div>
            {/* Ride Details */}
            <div className="w-[50%] flex flex-col h-full justify-between">
              <div className="w-full h-full">
                <img src="https://i.imgur.com/tmJaIui.png" alt="Car Image" className="w-full h-full rounded-xl" />
              </div>
              <div className="w-full h-full">

              <div className="flex flex-row justify-between mt-2 mb-4">
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  {dialogData.ride}
                </Typography>
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  {dialogData.amount}
                </Typography>
              </div>
              <div className="gap-4 flex flex-row justify-between h-full w-full">
                <div>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Rider Name:
                    <span className="font-normal">Pranit Sharma</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Ride:<span className="font-normal">SUV Sedan</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Pickup:<span className="font-normal">New York</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Drop Off:<span className="font-normal">Bramptons</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                    Date:<span className="font-normal">04.06.24</span>
                  </Typography>
                </div>

                <div className="border-[1px] border-primaryLight-400 p-4 items-center rounded-lg">
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 w-full">
                    Driver Details
                  </Typography>
                  <div className="w-[95%] border-[1px] border-primaryLight-400 my-1"></div>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                    Driver:{" "}
                    <span className="font-normal">{dialogData.driver}</span>
                  </Typography>
                  <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1 w-full">
                    Driver ID: <span className="font-normal">LCS005</span>
                  </Typography>
                </div>
              </div>

              <div className="mt-4">
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Note for Driver:{" "}
                  <span className="font-normal">
                    Please call when you reach the location.
                  </span>
                </Typography>
              </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </DialogBody>
      </Dialog>
    </div>
  );
};

export default RIdeDetailsTable;
