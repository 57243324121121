import { Button, Typography } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import {
  useRegisterMutation,
  useResendOTPMutation,
} from "../../../redux/api/authApi";
import { useVerifyOTPMutation } from "../../../redux/api/userApi";

const Otp = () => {
  const location = useLocation();
  const { email, forgot } = location.state || {};

  const [seconds, setSeconds] = useState(60);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [register] = useRegisterMutation();
  const [resendOTP] = useResendOTPMutation();
  const [verifyOTP] = useVerifyOTPMutation();

  const handleChange = (element, index) => {
    // if (isNaN(element.value)) return;

    let newOtp = [...otp];
    newOtp[index] = element.value;

    setOtp(newOtp);

    // Focus next input if available
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };

  const handleOTP = async (e) => {
    e.preventDefault();
    const OTP = otp.join("");
    console.log(OTP);
    setLoading(true);
    try {
      const res = await register({
        email: email,
        otp: OTP,
      });
      if (res.data.success) {
        localStorage.setItem("token", res.data.token);
        setLoading(false)
        navigate("/");
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(timerId);
    } else {
      setIsResendEnabled(true);
    }
  }, [seconds]);

  const resendOTPHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await resendOTP({ email: email });
      if (data.success) {
        setSeconds(60);
        setIsResendEnabled(false);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleForgotOTP = async (e) => {
    e.preventDefault();
    const OTP = otp.join("");
    setLoading(true)
    try {
      const { data } = await verifyOTP({
        otp: OTP,
        email: email,
      });
      if (data.success) {
        setLoading(false)
        navigate("/new-password", { state: { email: email } });
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center w-full bg-cover"
      style={{
        backgroundImage: `url("https://i.imgur.com/4GCorK6.png")`,
        backgroundRepeat: "no-repeat",
        height: `calc(100vh - 5rem)`,
      }}>
      <div className="h-[45%] flex flex-col items-center justify-center w-full">
        <Typography className="text-left text-5xl mb-4 font-bold font-playfair text-primaryLight-50">
          Welcome to LCS!
        </Typography>
        <Typography className="text-left text-lg font-semibold mb-20 font-montserrat text-primaryLight-50">
          Enter otp to book your favourite fleet. We sent a code to{" "}
          <span className="text-[#ffc978]">{email}</span>
        </Typography>
      </div>

      <div className="flex justify-center h-[55%] w-full items-center bg-black bg-opacity-90 backdrop-blur-0 !backdrop:filter-none">
        <div className="w-full h-full max-w-[454px] px-6 pt-10 rounded-t-xl text-primaryLight-50">
          <Typography className="text-center text-3xl mb-4 font-bold font-playfair text-primaryLight-50">
            Enter OTP Received on Email
          </Typography>
          <Typography className="text-center text-sm font-semibold mb-6 font-montserrat text-primaryLight-50">
            We sent a code to <span className="text-[#ffc978]">{email}</span>
          </Typography>

          {/* OTP Input Fields */}
          <form onSubmit={forgot ? handleForgotOTP : handleOTP}>
            <div className="flex justify-center gap-4 mb-6">
              {otp.map((data, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  className="w-12 h-12 text-center text-2xl rounded-lg bg-[#302721] text-white border border-[#6b564a] focus:outline-none focus:ring-2 focus:ring-[#ffc978]"
                  value={data}
                  onChange={(e) => handleChange(e.target, index)}
                  onFocus={(e) => e.target.select()}
                />
              ))}
            </div>

            {/* <Typography
              className="text-left text-sm font-semibold mb-4 text-primaryLight-50"
              onClick={resendOTP}>
              Resend in: <span className="text-[#ffc978]">01:00 min</span>
            </Typography> */}

            <div className="text-left text-sm font-semibold mb-4 text-primaryLight-50">
              {isResendEnabled ? (
                <button
                  className="text-[#ffc978] font-semibold"
                  onClick={resendOTPHandler}>
                  Resend Code
                </button>
              ) : (
                <p>Resend available in {seconds}s</p>
              )}
            </div>

            <Button
              type="submit"
              className="w-full py-3 text-center bg-[#ffc978] bg-opacity-60 text-[#251e1a] font-semibold rounded-lg hover:bg-yellow-700 transition" disabled={loading}>
              {loading ? "...." : "Continue"}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Otp;
