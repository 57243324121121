import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import carLocationImg from "../../../../assets/images/carLocationImg.svg";
import {
  useAssignDriverMutation,
  useDriversQuery,
  usePendingRequestsQuery,
} from "../../../../redux/api/admin/adminApi";
import debounce from "lodash.debounce";

const PendingRequests = () => {
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const { data: pendingRequest, isLoading } = usePendingRequestsQuery({
    page,
    token,
  });
  const { data: driverList } = useDriversQuery(token);
  const [assignDriver] = useAssignDriverMutation();

  const [open, setOpen] = useState(false);
  const [assignRotate, setAssignRotate] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);

  const drivers = driverList?.driver?.map((driver) => ({
    id: driver?._id,
    name: driver?.name,
    uid: driver?.uid,
  }));

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData({ ...items, requestId: items?._id });
      handleOpen();
    }
  };

  // Debounce search input
  const handleSearch = debounce((value) => {
    const filtered = drivers?.filter((driver) =>
      driver.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredDrivers(filtered);
  }, 300);

  useEffect(() => {
    setFilteredDrivers(drivers); // Initially, all drivers are shown
  }, []);

  const handleDriverSelect = (driver) => {
    setSelectedDriver(driver);
  };

  const handleAssignDriver = async () => {
    console.log(selectedDriver);
    console.log("Request ID", dialogData?.requestId);
    const driverId = selectedDriver.id;
    const requestId = dialogData?.requestId;
    try {
      const { data } = await assignDriver({
        token,
        params: { requestId, driverId },
      });
      if (data.success) {
        console.log("Driver Assigned");

        handleOpen();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const TABLE_HEAD = ["Date", "Ride", "Pickup", "Drop Off", "Amount"];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Pending Requests{" "}
          <span className="text-red-500 font-montserrat font-normal text-base">
            (Action Required)
          </span>
        </Typography>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${head === "Amount" && "rounded-tr-xl rounded-br-xl"}`}>
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {pendingRequest?.pendingRequests?.map((items, index) => (
                <tr
                  key={index}
                  className="even:bg-primaryLight-800 odd:bg-[#111111] overflow-y-auto">
                  <td className="p-4 rounded-tl-xl rounded-bl-xl">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items?.fromDate?.split("T")[0]}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.rideType}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.pickup.address}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.dropof.address}
                    </Typography>
                  </td>
                  <td className="p-4 rounded-tr-xl rounded-br-xl">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.price}
                    </Typography>
                  </td>
                  <td className="p-4 bg-[#111111] text-center">
                    <Typography
                      className="font-normal font-montserrat text-lg text-textLight-500 cursor-pointer"
                      onClick={() => handleDialog(items)}>
                      Assign
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={page === 1}
          variant="outlined"
          color="blue-gray"
          className="text-primaryLight-50">
          Previous
        </Button>
        <Typography className="text-primaryLight-50">
          Page {page} of {pendingRequest?.totalPages}
        </Typography>
        <Button
          onClick={() => {
            if (page < pendingRequest?.totalPages) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
          disabled={page >= pendingRequest?.totalPages}
          variant="outlined"
          color="blue-gray"
          className="text-primaryLight-50">
          Next
        </Button>
      </div>

      {/* Dialog Component */}
      <Dialog
        open={open}
        handler={handleOpen}
        size="md"
        className="bg-primaryLight-800 rounded-xl p-4">
        <div className="w-full flex flex-row justify-between">
          <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
            Ride Details
          </Typography>
          <Typography
            className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
            onClick={handleOpen}>
            x
          </Typography>
        </div>
        <DialogBody>
          <div className="flex flex-row justify-between items-start gap-6">
            {/* Map / Image */}
            <div className="rounded-xl w-[52%] h-full">
              <img src={carLocationImg} alt="Map" className="w-full h-full" />
            </div>
            {/* Ride Details */}
            <div className="w-[48%] min-h-[310px]">
              <div className="rounded-xl w-full h-[163px] bg-[#FFD4AD33]">
                <img
                  src={dialogData?.vehicle?.carImg}
                  alt="car"
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex flex-row justify-between mt-2">
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  {dialogData?.vehicle?.rideType}
                </Typography>
                <Typography className="text-xl font-semibold font-playfair text-primaryLight-200">
                  ${dialogData.price}
                </Typography>
              </div>
              <div className="text-xs font-bold font-montserrat mt-2">
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Rider Name:{" "}
                  <span className="font-normal">{dialogData?.user?.name}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Pickup:{" "}
                  <span className="font-normal">
                    {dialogData?.pickup?.address}
                  </span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Drop Off:{" "}
                  <span className="font-normal">
                    {dialogData?.dropof?.address}
                  </span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Date:{" "}
                  <span className="font-normal">
                    {dialogData?.fromDate?.split("T")[0]}
                  </span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Time:{" "}
                  <span className="font-normal">
                    {dialogData?.fromDate?.split("T")[1]?.split(":")[0] +
                      ":" +
                      dialogData?.fromDate?.split("T")[1]?.split(":")[1]}
                  </span>
                </Typography>
              </div>
            </div>
          </div>

          {/* Driver Selection */}
          <div className="mt-4">
            <div className="relative">
              <Button
                onClick={() => setAssignRotate(!assignRotate)}
                color="blue-gray"
                variant="outlined"
                className="flex items-center justify-between w-full bg-opacity-10 text-primaryLight-50">
                {selectedDriver ? selectedDriver.name : "Assign Driver"}
                <ChevronDownIcon
                  className={`h-5 w-5 transition-transform ${
                    assignRotate ? "rotate-180" : ""
                  }`}
                />
              </Button>
              {assignRotate && (
                <div className="absolute w-full bg-primaryLight-600 bg-opacity-50 shadow-lg rounded-md z-10 mt-2 p-2">
                  {/* Search input */}
                  <Input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                      handleSearch(e.target.value); // Use debounce
                    }}
                    placeholder="Search driver..."
                    className="w-full bg-primaryLight-50 text-primaryLight-900"
                  />
                  {/* Filtered driver list */}
                  <ul className="mt-2 max-h-40 overflow-auto">
                    {filteredDrivers?.map((driver) => (
                      <li
                        key={driver.id}
                        onClick={() => handleDriverSelect(driver)}
                        className="p-2 cursor-pointer hover:bg-primaryLight-400 hover:text-primaryLight-900 rounded-md">
                        {driver.name} ID:{driver.uid}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </DialogBody>
        <DialogFooter className="flex justify-center">
          <Button
            variant="outlined"
            size="sm"
            className="flex items-center justify-around gap-3 min-w-[105px] text-base rounded-md font-normal normal-case bg-[#FFC978] text-primaryLight-900 border-primaryLight-900 tracking-normal pl-6"
            onClick={() => {
              console.log(`Assigned Driver ID: ${selectedDriver?.id}`);
              handleAssignDriver();
            }}>
            Assign Driver
          </Button>
          <Button
            variant="outlined"
            size="lg"
            color="red"
            onClick={handleOpen}
            className="border border-red-500 text-red-500 font-normal ml-4 px-4 py-2 rounded-md normal-case">
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default PendingRequests;
