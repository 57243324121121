import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  Tab,
  Tabs,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import { useGetAllDriversQuery } from "../../../../redux/api/admin/adminApi";

const DriverDetailsTable = ({ payload }) => {
  console.log("payload", payload);

  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [assignRotate, setAssignRotate] = useState(false);
  const [activeTab, setActiveTab] = useState("All");

  const { data: driverDetails } = useGetAllDriversQuery({
    token: payload,
    page,
  });

  const [dialogData, setDialogData] = useState({
    date: "04.06.24",
    driver: "Manish K Singh",
    fleet: "Premium SUV",
    driverId: "LCS001",
  });

  const tabData = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Premium SUV",
      value: "Premium SUV",
    },
    {
      label: "Premium Sedan",
      value: "Premium Sedan",
    },
    {
      label: "First Class",
      value: "First Class",
    },
  ];

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDialog = (items) => {
    if (items) {
      setDialogData(items);
      handleOpen();
    }
  };

  // Updated TABLE_HEAD and TABLE_ROWS based on screenshot
  const TABLE_HEAD = ["Date", "Name", "Driver Id", "Fleet Type"];

  const handleDelete = async (id) => {
    console.log("Driver Id", id);
    handleOpen();
  };

  return (
    <div className="bg-transparent border-[2px] border-primaryLight-300 p-6 rounded-[20px] text-primaryLight-50">
      <div className="flex justify-between items-center mb-8">
        <Typography className="text-primaryLight-50 text-5xl font-bold font-playfair">
          Driver's Details
        </Typography>
      </div>
      <div className="w-full flex flex-row justify-between items-center">
        <div className="w-[70%] mb-6">
          <Tabs value={activeTab} className="bg-transparent">
            <TabsHeader
              className="bg-transparent"
              indicatorProps={{
                className:
                  "bg-transparent border-b-[2px] rounded-none border-[#FFC978]",
              }}>
              {tabData.map(({ label, value }, index) => (
                <Tab
                  key={value}
                  value={value}
                  className={`font-normal ${
                    activeTab === value
                      ? "!text-[#FFC978]"
                      : "!text-primaryLight-300"
                  }`}
                  onClick={() => setActiveTab(value)}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </Tabs>
        </div>
        <div className="w-[25%] flex justify-center items-center mb-6">
          <Button
            variant="outlined"
            size="sm"
            className="flex items-center justify-around gap-3 min-w-[175px] text-base rounded-md font-normal normal-case border-[#FFC978] text-[#FFC978] tracking-normal pl-6"
            onClick={() => navigate("/user-details/add-driver")}>
            Add Driver
          </Button>
        </div>
      </div>
      <div className="overflow-hidden">
        <div className="h-full w-full">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr className="rounded-lg">
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className={`bg-primaryLight-700 p-4 ${
                      head === "Date" && "rounded-tl-xl rounded-bl-xl"
                    } ${
                      head === "Fleet Type" && "rounded-tr-xl rounded-br-xl"
                    }`}>
                    <Typography className="font-semibold font-montserrat text-lg text-primaryLight-50">
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {driverDetails?.drivers?.map((items, index) => (
                <tr
                  key={index}
                  className="even:bg-primaryLight-800 odd:bg-[#111111]">
                  <td className="p-4 rounded-tl-xl rounded-bl-xl">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.date.split("T")[0]}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.name}
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.uid}
                    </Typography>
                  </td>
                  <td className="p-4 rounded-tr-xl rounded-br-xl">
                    <Typography className="font-normal font-montserrat text-lg text-primaryLight-50">
                      {items.fleet}
                    </Typography>
                  </td>
                  <td className="p-4 bg-[#111111] text-center rounded-tr-xl rounded-br-xl">
                    <Typography
                      className="font-normal font-montserrat text-lg text-textLight-500 cursor-pointer"
                      onClick={() => handleDialog(items)}>
                      View Details
                    </Typography>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Control */}
      <div className="flex justify-between items-center mt-4">
        <Button
          onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
          disabled={page === 1}
          variant="outlined"
          color="blue-gray"
          className="text-primaryLight-50">
          Previous
        </Button>
        <Typography className="text-primaryLight-50">
          Page {page} of {driverDetails?.totalPages}
        </Typography>
        <Button
          onClick={() => {
            if (page < driverDetails?.totalPages) {
              setPage((prevPage) => prevPage + 1);
            }
          }}
          disabled={page >= driverDetails?.totalPages}
          variant="outlined"
          color="blue-gray"
          className="text-primaryLight-50">
          Next
        </Button>
      </div>

      {/* Dialog Component */}
      <Dialog
        open={open}
        handler={handleOpen}
        size="md"
        className="bg-primaryLight-800 rounded-xl p-4">
        <div className="w-full flex flex-row justify-between">
          <Typography className="text-primaryLight-200 text-2xl font-bold font-playfair w-full text-center">
            Driver Details
          </Typography>
          <Typography
            className="text-primaryLight-200 text-xs font-bold font-playfair cursor-pointer"
            onClick={handleOpen}>
            x
          </Typography>
        </div>
        <DialogBody>
          <div className="flex flex-row justify-between items-start gap-6">
            {/* Driver Image */}
            <div className="rounded-xl w-[35%] h-full">
              <img
                src={dialogData.image} // Replace with the driver's image URL
                alt="Driver"
                className="w-full h-full object-cover rounded-md"
              />
            </div>
            {/* Driver Details */}
            <div className="w-[65%]">
              <div className="text-xs font-bold font-montserrat mt-2">
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Driver Name:{" "}
                  <span className="font-normal">{dialogData.name}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Ride: <span className="font-normal">{dialogData.fleet}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Mobile Number:{" "}
                  <span className="font-normal">{dialogData.phone}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Email: <span className="font-normal">{dialogData.email}</span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Date of Joining:{" "}
                  <span className="font-normal">
                    {dialogData.date.split("T")[0]}
                  </span>
                </Typography>
                <Typography className="text-xs font-bold font-montserrat text-primaryLight-200 mt-1">
                  Licence Number:{" "}
                  <span className="font-normal">
                    {dialogData.drivingLicenseNo}
                  </span>
                </Typography>
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter className="w-full flex flex-row justify-center gap-6">
          <Button
            variant="filled"
            size="sm"
            className="flex items-center justify-around gap-3 min-w-[175px] text-base rounded-md font-normal normal-case bg-[#FFC978] text-primaryLight-900 border-primaryLight-900 tracking-normal pl-6"
            onClick={() => {
              setAssignRotate(!assignRotate);
            }}>
            Edit Profile
          </Button>
          <Button
            variant="text"
            color="red"
            size="lg"
            onClick={() => handleDelete(dialogData?._id)}
            className="border border-red-500 text-red-500 font-normal px-4 py-2 rounded-md normal-case">
            Delete Profile
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default DriverDetailsTable;
