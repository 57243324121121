import { Button, Typography } from "@material-tailwind/react";
import React from "react";

const ScheduledCard = ({ items }) => {
  return (
    <div className="flex flex-row w-full py-8 gap-6">
      <div className="w-1/3">
        <img src={items.carImg} alt="car" className="w-full h-full" />
      </div>
      <div className="w-2/3 flex flex-col justify-between">
        <div className="flex w-full flex-row">
          <Typography className="text-left w-full text-xl font-semibold font-playfair text-primaryLight-200">
            {items.carType}
          </Typography>
          <Typography className="text-right w-full text-xl font-semibold font-playfair text-primaryLight-200">
            ${items.fare}
          </Typography>
        </div>
        <div className="flex w-full flex-row">
          <div className="flex w-full flex-col gap-2">
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              Ride:<span className="pl-[3px] font-normal">{items.ride}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              Pickup:<span className="pl-[3px] font-normal">{items.pickUp}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              Drop Off:<span className="pl-[3px] font-normal">{items.dropOff}</span>
            </Typography>
            <Typography className="text-left w-full text-xs font-semibold font-montserrat text-primaryLight-200">
              Date:<span className="pl-[3px] font-normal">{items.date}</span>
            </Typography>
          </div>
          <div className="flex flex-col w-full justify-end items-end">
            <Button
              variant="outlined"
              color="red"
              size="sm"
              className="normal-case w-[120px] rounded-[4px]"
            >
              Cancel Ride
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduledCard;
